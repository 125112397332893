
import { useStore } from "vuex"
import { NewEventItem, RadioOptionsTypes } from "@/types"
import { defineComponent, reactive, toRefs } from "vue"

import BaseInput from "@/components/BaseInput.vue"
import BaseSelect from "@/components/BaseSelect.vue"
// import BaseCheckbox from "@/components/BaseCheckbox.vue"
// import BaseRadioGroup from "@/components/BaseRadioGroup.vue"

import EventService from "@/services/EventService"
import { AxiosResponse } from "axios"
import { v4 as uuidv4 } from "uuid"

export default defineComponent({
  components: {
    BaseInput,
    BaseSelect,
    // BaseCheckbox,
    // BaseRadioGroup,
  },

  setup() {
    const store = useStore()
    const state = reactive({
      // Dropdown options
      categories: [
        "sustainability",
        "nature",
        "animal welfare",
        "housing",
        "education",
        "food",
        "community",
      ] as string[],
      // Esentially formData
      event: {
        id: "",
        category: "",
        title: "",
        description: "",
        location: "",
        organinser: "",
        date: "",
        time: "",
      } as NewEventItem,
      radioOptions: [
        {
          label: "yes",
          value: 1,
        },
        {
          label: "no",
          value: 0,
        },
      ] as RadioOptionsTypes,
    })

    const clearEventForm = (): NewEventItem => {
      return (state.event = {
        id: "",
        category: "",
        title: "",
        description: "",
        location: "",
        organinser: "",
        date: "",
        time: "",
      })
    }

    const sendForm = () => {
      const event = {
        ...state.event,
        id: uuidv4(),
        organinser: store.state.user,
      }
      EventService.postEvent(event)
        .then((response: AxiosResponse) => {
          store.commit("ADD_EVENT", event)

          console.log(response)
          clearEventForm()
        })
        .catch((error: Error) => {
          console.log(error)
        })
    }

    return {
      ...toRefs(state),
      sendForm,
      store,
    }
  },
})
