
import UniqueId from "@/features/UniqueId"
import { defineComponent } from "vue"

export default defineComponent({
  props: {
    label: {
      type: String,
      required: true,
    },
    modelValue: {
      type: [String, Number],
      required: true,
    },
    type: {
      type: String,
      default: "text",
    },
    error: {
      type: String,
      default: "",
    },
  },

  setup(props, { emit }) {
    const uuid = UniqueId().getId()

    const handleChange = (event: Event) => {
      const target = event.target as HTMLInputElement
      emit("update:modelValue", target.value)
    }

    return {
      uuid,
      handleChange,
    }
  },
})
