
import { defineComponent, PropType } from "vue"
import { SelectOptions } from "@/types"

import UniqueId from "@/features/UniqueId"

export default defineComponent({
  props: {
    label: {
      type: String,
      default: "",
    },

    modelValue: {
      type: [String, Number],
      default: "",
    },

    options: {
      type: Array as PropType<SelectOptions>,
      default: () => [],
      required: true,
    },
  },
  setup(props, { emit }) {
    const uuid = UniqueId().getId()
    const handleChange = (event: Event): void => {
      const target = event.target as HTMLSelectElement
      emit("update:modelValue", target.value)
    }

    return {
      uuid,
      handleChange,
    }
  },
})
