
import { defineComponent, reactive, toRefs } from "vue"
import EtsyService from "@/services/EtsyService"
import { EtsyResult } from "@/types"

export default defineComponent({
  setup() {
    const state = reactive({
      listings: [] as EtsyResult[],
    })

    EtsyService.getListings()
      .then((response) => {
        console.log(response)
        state.listings = response.data.results
      })
      .catch((error) => {
        console.log(error)
      })

    return {
      ...toRefs(state),
    }
  },
})
